import React from "react"
import { useState, useMemo } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
const Invoice = () => {
    const [sellerId, setSelleId] = useState("");
    const [invoiceId, setInvoiceId] = useState("");
    const [amount, setAmount] = useState("");
    const [invoiceType, setInvoiceType] = useState("commission");
    const [pdf, setPdf] = useState(null);
    const [sellerIdError, setSellerIdError] = useState("");
    const [otherError, setOtherError] = useState("");
    const [sellerName, setSellerName] = useState("");
    const [companyTitle, setCompanyTitle] = useState("");
    const [officeAddress, setOfficeAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [taxOffice, setTaxOffice] = useState("");
    const [taxNo, setTaxNo] = useState("");
    const [shopName, setShopName] = useState("");
    const getSellerInfo = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/invoice/get-user-info?user_id=${sellerId}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const {
        data: sellerInfo,
        isLoading: sellerInfoIsLoading,
        isError: sellerInfoIsError,
        error: sellerInfoError,
        refetch: sellerInfoRefetch
    } = useQuery({ queryKey: ["get-seller-info"], queryFn: getSellerInfo, enabled: false, retry: false })
    let sellerInfoData
    if (sellerInfoIsLoading) {
        sellerInfoData = "loading"
    } else if (sellerInfoIsError) {
        console.log(sellerInfoError.response.data.detail[0].msg);
        console.log(sellerInfoError);
        sellerInfoData = "error"
    } else {
        // console.log(sellerInfo?.data);
        sellerInfoData = sellerInfo?.data
        // setSellerName(sellerInfo.data.name)
    }
    useMemo(() => {
        setSellerName(sellerInfo?.data?.name_surname)
        setCompanyTitle(sellerInfo?.data?.company_title)
        setEmail(sellerInfo?.data?.email)
        setOfficeAddress(sellerInfo?.data?.office_address)
        setPhoneNumber(sellerInfo?.data?.phone_number)
        setTaxOffice(sellerInfo?.data?.tax_office)
        setTaxNo(sellerInfo?.data?.tax_no)
        setShopName(sellerInfo?.data?.shop_name)
        if (sellerInfoData === 'error') {
            setSellerName("Not Found")
            setCompanyTitle("Not Found")
            setEmail("Not Found")
            setOfficeAddress("Not Found")
            setPhoneNumber("Not Found")
            setTaxOffice("Not Found")
            setTaxNo("Not Found")
            setShopName("Not Found")
        }
    }, [sellerInfoData])
    const uploadInvoice = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/invoice/upload`
        const finalData = {
            seller_id: parseInt(sellerId),
            invoice_id: invoiceId,
            amount: parseFloat(amount),
            invoice_type: invoiceType,
            file: pdf
        }
        const formData = new FormData()

        for (const key in finalData) {
            formData.append(key, finalData[key])
        }
        // for (const [key, value] of formData.entries()) {
        // console.log(`${key}: ${value}`);
        // }
        return axios.post(finalUrl, formData, { withCredentials: true })
    }
    const invoiceMutation = useMutation({
        mutationFn: uploadInvoice,
        onSuccess: (data) => {
            // console.log(data);
            setSelleId("")
            setInvoiceId("")
            setAmount("")
            setInvoiceType("commission")
            setPdf(null)
            setSellerIdError("")
            setOtherError("")
            toast.success("Done")
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "invalid seller_id") {
                setSellerIdError("invalid seller_id")
            } else {
                setOtherError(error.response.data.detail[0].msg)
            }

            console.log(error);
        }
    })


    const handleSubmit = (e) => {
        e.preventDefault()
        invoiceMutation.mutate()

    }
    return (
        <>
            <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center vh-100 vw-100 ">
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#fff" size={50} />
                </Link>
                <Row className="d-flex justify-content-center align-items-center flex-row mb-5 mt-5 ">
                    <Col className="col-4 w-30 ">

                        <Image src="https://butiko-public-media-storage1.s3.eu-central-1.amazonaws.com/logo.png" fluid />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center   ">
                    <Col className="d-flex justify-content-center align-items-center ">

                        <Form onSubmit={handleSubmit}>
                            <h2>Invoice Submission</h2>
                            <Form.Group className="mb-3" controlId="formBasicSellerId">
                                <Form.Label>Seller ID:</Form.Label>
                                <Form.Control className={sellerIdError ? "border-danger " : ""} value={sellerId} onChange={(e) => setSelleId(e.target.value)} type="number" placeholder="Seller ID" required />
                                {sellerIdError && (<Form.Text className=" text-danger ">{sellerIdError}</Form.Text>)}
                            </Form.Group>
                            <Button onClick={sellerInfoRefetch} className="border-0 butiko-button" variant="primary" type="button">
                                verif
                            </Button>
                            <Form.Group className="mb-3" controlId="formBasicInvoiceId">
                                <Form.Label>Name - Surname:</Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={sellerName} type="text" placeholder="Name" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Company Title: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={companyTitle} type="text" placeholder="Company titile" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Shop Name: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={shopName} type="text" placeholder="Shop name" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Office Address: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={officeAddress} type="text" placeholder="Office address" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Email: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={email} type="text" placeholder="Email" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Phone Number: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={phoneNumber} type="text" placeholder="Phone number" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Tax Office: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={taxOffice} type="text" placeholder="Tax Office" required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Tax No: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={taxNo} type="text" placeholder="Tax No" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicInvoiceId">
                                <Form.Label>Invoice ID:</Form.Label>
                                <Form.Control value={invoiceId} onChange={(e) => setInvoiceId(e.target.value)} type="text" placeholder="Invoice ID" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Amount: </Form.Label>
                                <Form.Control value={amount} onChange={(e) => setAmount(e.target.value)} type="number" placeholder="Amout" required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Invoice Type:</Form.Label>
                                <Form.Select value={invoiceType} onChange={(e) => setInvoiceType(e.target.value)} className="mb-3" aria-label="Default select example">
                                    <option value="commission">Commission</option>
                                    <option value="token">Token</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-5" controlId="formBasicPassword">
                                <Form.Label>Upload PDF:: </Form.Label>
                                <Form.Control onChange={(e) => setPdf(e.target.files[0])} accept=".pdf" type="file" placeholder="Amout" required />
                                {pdf && (<Form.Text>File Name: {pdf.name}</Form.Text>)}
                            </Form.Group>
                            {otherError && (
                                <p className="text-danger ">{otherError}</p>
                            )}

                            <Button className="border-0 butiko-button" variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default Invoice