import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState, useMemo } from 'react';
const useGetOrders = (page) => {
    // const [page, setPage] = useState(initialPage);
    const [isNext, setIsNext] = useState(false);
    const getOrders = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/order/get-order-data?page=${page}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-Orders", page], queryFn: getOrders });

    const orders = data?.data;
    useMemo(() => {

        if (Array.isArray(orders) && orders.length === 13) {
            setIsNext(true)
        } else {
            setIsNext(false)
        }
    }, [data])

    // const tokenIds = Array.isArray(tokens) ? tokens.map(token => token.transaction_id || null).filter(id => id !== null) : [];

    return {
        orders,
        isLoading,
        isError,
        error,
        refetch,
        isNext,
    };
};

export default useGetOrders;