import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import formatDate from '../../../components/formatDate';
const OrderDetailModal = ({ isModalOpen, setIsModalOpen, orderDetails }) => {


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>

            <Modal
                title="Order Details"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="90vw"
                style={{ top: 90 }}
                styles={{
                    body: {
                        maxHeight: 'calc(90vh - 200px)',
                        overflow: 'auto'
                    }
                }}
            >                <div className="p-6">
                    <h2 className="text-lg font-bold mb-4">Order Details</h2>
                    <table className="min-w-full table-auto border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-300 px-4 py-2 text-left">Product ID</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Product Name</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Return Shipping Ref No</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Return Shipping Tracking No</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Return Shipping Tracking URL</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Seller ID</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Shipping Ref No</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Shipping Tracking No</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Shipping Tracking URL</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Shop Name</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Status</th>
                                <th className="border border-gray-300 px-4 py-2 text-left">Success Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails.map((order, index) => (
                                <tr
                                    key={index}
                                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                                >
                                    <td className="border border-gray-300 px-4 py-2">{order.product_id ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{order.product_name ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{order.return_shipping_ref_no ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{order.return_shipping_tracking_no ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">
                                        {order.return_shipping_tracking_url ? (
                                            <a
                                                href={order.return_shipping_tracking_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:text-blue-800 underline"
                                            >
                                                Track Return
                                            </a>
                                        ) : "N/A"}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">{order.seller_id ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{order.shipping_ref_no ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{order.shipping_tracking_no ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">
                                        {order.shipping_tracking_url ? (
                                            <a
                                                href={order.shipping_tracking_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:text-blue-800 underline"
                                            >
                                                Track Shipping
                                            </a>
                                        ) : "N/A"}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2">{order.shop_name ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{order.status ?? "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{formatDate(order.success_date) ?? "N/A"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
};
export default OrderDetailModal;