import React, { useState } from 'react';
import { Plus, Minus, ChevronRight, ChevronDown } from 'lucide-react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import PreviewModal from './modals/PreviewModal';

const CategoryNode = ({ category, depth, onUpdate, onDelete }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [newSubcategory, setNewSubcategory] = useState('');

    const handleNameChange = (e) => {
        onUpdate({ ...category, category_name: e.target.value });
    };

    const handleAddSubcategory = () => {
        if (newSubcategory && depth < 2) {
            onUpdate({
                ...category,
                children_categories: [
                    ...(category.children_categories || []),
                    { category_name: newSubcategory, children_categories: [] }
                ]
            });
            setNewSubcategory('');
        }
    };

    return (
        <div className="border rounded p-3 mb-2">
            <div className="flex items-center mb-2">
                {depth > 0 && (
                    <button onClick={() => onDelete()} className="mr-2 text-red-500">
                        <Minus size={16} />
                    </button>
                )}
                {category.children_categories?.length > 0 && (
                    <button onClick={() => setIsExpanded(!isExpanded)} className="mr-2">
                        {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                    </button>
                )}
                <input
                    type="text"
                    value={category.category_name}
                    onChange={handleNameChange}
                    className="border rounded px-2 py-1 flex-grow"
                    placeholder='parent'
                />
            </div>

            {isExpanded && (
                <>
                    {category.children_categories?.map((subcat, index) => (
                        <div key={index} className="ml-4">
                            <CategoryNode
                                category={subcat}
                                depth={depth + 1}
                                onUpdate={(updatedSubcat) => {
                                    const newChildren = [...category.children_categories];
                                    newChildren[index] = updatedSubcat;
                                    onUpdate({ ...category, children_categories: newChildren });
                                }}
                                onDelete={() => {
                                    const newChildren = category.children_categories.filter((_, i) => i !== index);
                                    onUpdate({ ...category, children_categories: newChildren });
                                }}
                            />
                        </div>
                    ))}

                    {depth < 2 && (
                        <div className="ml-4 mt-2 flex">
                            <input
                                type="text"
                                value={newSubcategory}
                                onChange={(e) => setNewSubcategory(e.target.value)}
                                placeholder="New subcategory"
                                className="border rounded px-2 py-1 flex-grow"
                            />
                            <button onClick={handleAddSubcategory} className="ml-2 bg-blue-500 text-white px-2 py-1 rounded">
                                <Plus size={16} />
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const CreateCategory = () => {
    const [show, setShow] = useState(false);
    const [rootCategory, setRootCategory] = useState({
        category_name: '',
        children_categories: []
    });
    // const [showPreview, setShowPreview] = useState(false);
    const handleExport = () => {
        console.log(JSON.stringify(rootCategory, null, 2));
        // setShowPreview(true);
        setShow(true)
    };
    return (
        <Container className="d-flex justify-content-center ">
            <Row className="flex justify-start ">
                <Col className="flex justify-start ">
                    <Link
                        style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                        to={"/categories"}
                    >
                        <IoChevronBack color="#fff" size={50} />
                    </Link>
                </Col>
            </Row>
            <div className="container mt-28 mx-auto p-6 overflow-x-auto rounded-t-lg bg-gray-100 custom-scrollbar">
                <div className="p-4">
                    <h2 className="text-2xl font-bold mb-4">Category Creator</h2>
                    <CategoryNode
                        category={rootCategory}
                        depth={0}
                        onUpdate={setRootCategory}
                        onDelete={() => { }}
                    />
                    <button
                        onClick={handleExport}
                        className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                    >
                        Create
                    </button>
                    
                </div>
            </div>
            <PreviewModal show={show} setShow={setShow} category={rootCategory}/>
        </Container>
    );
};

export default CreateCategory;