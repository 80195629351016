import React from "react"
import { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useGetCategories from "../Categories/hooks/useGetCategories";
import { Form } from 'react-bootstrap';
import useGetAttributes from "./hooks/useGetAttributes";
import AttributeValues from "./Components/AttributeValues";

const Attributes = () => {
    const [level1, setLevel1] = useState('');
    const [level2, setLevel2] = useState('');
    const [level3, setLevel3] = useState('');
    const [level2Options, setLevel2Options] = useState([]);
    const [level3Options, setLevel3Options] = useState([]);
    const [deepestCategoryId, setDeepestCategoryId] = useState(0);
    const {
        categories,
        isLoading,
        isError,
        error,
        refetch
    } = useGetCategories()
    const {
        attributes,
        isLoading: attIsLoading,
        isError: attIsError,
        error: attError,
        refetch: attRefetch
    } = useGetAttributes(deepestCategoryId)


    useEffect(() => {
        if (level1) {
            const selectedCategory = categories.find(cat => cat.category_id.toString() === level1);
            setLevel2Options(selectedCategory?.children_categories || []);
            setLevel2('');
            setLevel3('');
            setDeepestCategoryId(level1);
        } else {
            setLevel2Options([]);
            setLevel3Options([]);
            setDeepestCategoryId(null);
        }
    }, [level1]);

    useEffect(() => {
        if (level2) {
            const selectedCategory = level2Options.find(cat => cat.category_id.toString() === level2);
            setLevel3Options(selectedCategory?.children_categories || []);
            setLevel3('');
            setDeepestCategoryId(level2);
        } else {
            setLevel3Options([]);
            if (level1) setDeepestCategoryId(level1);
        }
    }, [level2]);

    useEffect(() => {
        if (level3) {
            setDeepestCategoryId(level3);
        } else if (level2) {
            setDeepestCategoryId(level2);
        }
    }, [level3]);
    const handleLevel1Change = (e) => {
        setLevel1(e.target.value);
        setLevel2('');
        setLevel3('');
    };
    const handleLevel2Change = (e) => {
        setLevel2(e.target.value);
        setLevel3('');
    };
    return (
        <>
            <Container className="d-flex justify-content-center ">
                <Row className="flex justify-start ">
                    <Col className="flex justify-start ">
                        <Link
                            style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                            to={"/nextpageoflogin"}
                        >
                            <IoChevronBack color="#fff" size={50} />
                        </Link>
                    </Col>
                </Row>
                <div className="container mt-9 mx-auto p-6 overflow-x-auto rounded-t-lg  custom-scrollbar">
                    <div className="p-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold text-white">Attributes</h2>
                            <Link className="link-style" to="/attribute-create">
                                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                                    New Attributes
                                </button>
                            </Link>
                        </div>
                        <div className="space-y-4">
                            <Form.Select
                                value={level1}
                                onChange={handleLevel1Change}
                                className="w-full p-2 border border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                                <option value="">Select Level 1 Category</option>
                                {Array.isArray(categories) && categories.map((category) => (
                                    <option key={category.category_id} value={category.category_id}>
                                        {Array.isArray(category.children_categories) && category.children_categories.length > 0 ? ("+") : (null)} {category.category_name}
                                    </option>
                                ))}
                            </Form.Select>
                            {level2Options.length > 0 && (
                                <Form.Select
                                    value={level2}
                                    onChange={handleLevel2Change}
                                    className="w-full p-2 border border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                >
                                    <option value="">Select Level 2 Category</option>
                                    {level2Options.map((category) => (
                                        <option key={category.category_id} value={category.category_id}>
                                            {Array.isArray(category.children_categories) && category.children_categories.length > 0 ? ("+") : (null)} {category.category_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            )}
                            {level3Options.length > 0 && (
                                <Form.Select
                                    value={level3}
                                    onChange={(e) => setLevel3(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                >
                                    <option value="">Select Level 3 Category</option>
                                    {level3Options.map((category) => (
                                        <option key={category.category_id} value={category.category_id}>
                                            {category.category_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            )}
                            {Array.isArray(attributes) && attributes.length > 0 && <AttributeValues attRefetch={attRefetch} attributesData={attributes} />}
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Container>
        </>
    )
}
export default Attributes