// CampaignList.jsx
import React, { useState } from 'react';
import { Card, Container, Row, Col, Badge, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import useGetCampaigns from './hooks/useGetCampaigns';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { message, Upload, Button, Progress, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const Campaigns = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [campaignId, setCampaignId] = useState(0);
    const {
        campaigns,
        isLoading,
        isError,
        error,
        refetch
    } = useGetCampaigns()
    const toggleCampaign = ({ id, status }) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/attributes/change-campaign-status`
        const finalData = {
            campaign_id: id,
            enable: status
        }
        return axios.put(finalUrl, finalData, { withCredentials: true })
    }
    const toggleCampaignMutation = useMutation({
        mutationFn: toggleCampaign,
        onSuccess: () => {
            refetch()
        },
        onError: (error) => {
            console.log(error);
            message.error("Error")
        }
    })
    const handleToggle = (campaignId, status) => {
        toggleCampaignMutation.mutate({ id: campaignId, status: status === 0 ? 1 : 0 });
        console.log(`Campaign ${campaignId} status toggled`);
    };
    const addBanner = (formData) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/attributes/add-banner`
        const finalData = formData
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const addBannerMutation = useMutation({
        mutationFn: addBanner,
        onSuccess: () => {
            refetch()
        },
        onError: (error) => {
            message.error("Error")
        }
    })

    const beforeUpload = (file) => {
        const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const isAcceptedType = acceptedTypes.includes(file.type);
        if (!isAcceptedType) {
            message.error('You can only upload PNG, JPEG or JPG files!');
            return Upload.LIST_IGNORE;
        }
        const isLessThan10M = file.size / 1024 / 1024 < 10;
        if (!isLessThan10M) {
            message.error('File must be smaller than 10MB!');
            return Upload.LIST_IGNORE;
        }
        return false; // Allow the file to be added to the list
    };
    const handleUpload = (info, id) => {
        setCampaignId(id)
        const file = info.file;
        const formData = new FormData();
        formData.append('banner', file);
        formData.append('campaign_id', id);
        addBannerMutation.mutate(formData)
    };
    return (
        <Container className="py-4 d-flex justify-content-center ">
            <Row className="flex justify-start ">
                <Col className="flex justify-start ">
                    <Link
                        style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                        to={"/nextpageoflogin"}
                    >
                        <IoChevronBack color="#fff" size={50} />
                    </Link>
                </Col>
            </Row>
            <div className="container mt-20 mx-auto p-4">

                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-white">Campaign List</h2>
                    <Link className="link-style" to="/campaigns-create">
                        <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            New Campaign
                        </button>
                    </Link>
                </div>

                <Row xs={1} md={2} lg={3} className="g-4 mt-20">
                    {isLoading ? (
                        [1, 2, 3].map((_, index) => (
                            <div key={index} className="animate-pulse">
                                <div className="w-full h-48 bg-gray-200 rounded-lg mb-2"></div>
                                <Card.Body>
                                    <div className="flex justify-between items-center mb-3">
                                        <div className="w-1/2 h-6 bg-gray-200 rounded"></div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-12 h-6 bg-gray-200 rounded"></div>
                                            <div className="w-10 h-6 bg-gray-200 rounded"></div>
                                        </div>
                                    </div>
                                    <hr className="border-t-2 border-gray-300 my-6" />
                                    <div>
                                        <div className="mb-2 w-1/3 h-4 bg-gray-200 rounded"></div>
                                        <div className="flex items-center mb-2">
                                            <div className="w-1/4 h-4 bg-gray-200 rounded"></div>
                                            <div className="w-5 h-5 bg-gray-200 rounded ml-2"></div>
                                        </div>
                                        <div className="flex flex-wrap gap-2">
                                            <div className="w-12 h-6 bg-gray-200 rounded"></div>
                                            <div className="w-12 h-6 bg-gray-200 rounded"></div>
                                            <div className="w-12 h-6 bg-gray-200 rounded"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </div>
                        ))
                    ) : isError ? (
                        <div style={{width:"100%"}} className="p-4 border border-red-500 bg-red-100 text-red-600  rounded-lg">
                            <div className="flex items-center justify-between">
                                <div className="text-lg font-semibold">Error Loading Campaign</div>
                                <div className="text-sm">{error?.message || "Something went wrong"}</div>
                            </div>
                            <p className="mt-2">Please try again later or contact support if the issue persists.</p>
                        </div>
                    ) : (Array.isArray(campaigns) && campaigns.map((campaign) => (
                        <Col className='flex' key={campaign.campaign_id}>

                            <Card>
                                {campaign.banner ? (
                                    <Card.Img
                                        variant="top"
                                        src={campaign.banner}
                                        alt={campaign.title}
                                        style={{ height: '200px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '200px', border: '1px dashed #ccc' }}>
                                        {addBannerMutation.isPending && campaignId === campaign.campaign_id ? (
                                            <Spin size="large" />
                                        ) : (
                                            <>
                                                <span className="text-muted mb-2">Upload a new banner</span>
                                                <Upload
                                                    beforeUpload={beforeUpload}
                                                    maxCount={1}
                                                    accept=".png, .jpg, .jpeg"
                                                    fileList={[]}
                                                    onChange={(info) => handleUpload(info, campaign.campaign_id)}
                                                >
                                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                </Upload>
                                            </>

                                        )}

                                    </div>
                                )}
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <Card.Title>{campaign.title}</Card.Title>
                                        <div className="d-flex align-items-center gap-2">
                                            <Badge bg={campaign.is_enabled ? 'success' : 'secondary'}>
                                                {campaign.is_enabled ? 'Active' : 'Inactive'}
                                            </Badge>
                                            <Form.Check
                                                type="switch"
                                                disabled={toggleCampaignMutation?.isPending}
                                                id={`campaign-switch-${campaign.campaign_id}`}
                                                checked={campaign.is_enabled === 1}
                                                onChange={() => {
                                                    if (!campaign.banner) {
                                                        message.warning("Upload a Banner please")
                                                    } else {

                                                        handleToggle(campaign.campaign_id, campaign.is_enabled)
                                                    }
                                                }}
                                                className="m-0"
                                            />
                                        </div>
                                    </div>

                                    <hr className="border-t-2 border-blue-500 my-6" />
                                    <div>
                                        <h6 className="text-muted">Filters:</h6>
                                        <div className="mb-2">
                                            {campaign.filter.category_id && (
                                                <div className="mb-2 ">
                                                    {/* <i className="bi bi-tag me-2"></i> */}
                                                    Category ID: {campaign.filter.category_id}
                                                </div>
                                            )}

                                            {campaign.filter.color && (
                                                <div className="d-flex align-items-center mb-2">
                                                    <span className="me-2">Color:</span>
                                                    <div
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundColor: campaign.filter.color,
                                                            border: '1px solid #dee2e6',
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {campaign.filter.attribute_value_ids.length > 0 && (
                                                <div>
                                                    <div className="mb-1">Attribute IDs:</div>
                                                    <div className="d-flex flex-wrap gap-2">
                                                        {campaign.filter.attribute_value_ids.map((id) => (
                                                            <Badge
                                                                key={id}
                                                                bg="secondary"
                                                                text="white"
                                                                className="px-2 py-1"
                                                            >
                                                                {id}
                                                            </Badge>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )))}
                </Row>
                {contextHolder}
            </div>
        </Container>
    );
};

export default Campaigns;