import React from "react"
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { RxCross2 } from "react-icons/rx";
const NotificationPage = () => {
    // const loggedInStatus = localStorage.getItem('isLoggedIn');
    // useEffect(() => {

    //     const isLoggedIn = localStorage.getItem('isLoggedIn');
    //     if (!isLoggedIn) {
    //         history("/")
    //     } else {
    //         // setLoggedInStatus(isLoggedIn);
    //     }
    // }, [history])
    const [userType, setUserType] = useState("all_users");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [userIds, setUserIds] = useState([]);
    const pushNotification = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/notification/admin-generate-notification`
        const finalData = {
            type: userType,
            title: title,
            content: content,
            ...(userType==="group" && {user_ids: userIds})
        }
        // console.log(finalData);
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const notificationMutation = useMutation({
        mutationFn: pushNotification,
        onSuccess: (data) => {
            // console.log(data);
            setContent("")
            setTitle("")
            toast.success("Done")
            setUserIds([])
        },
        onError: (error) => {
            console.log(error);
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        notificationMutation.mutate();

    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const newValue = parseInt(e.target.value.trim());
            if (newValue) {
                setUserIds((prevValue) => [...prevValue, newValue]);
                e.target.value = ''; // Clear the input field
            }
        }
    }
    const handleUserDelet = (userId) => {
        // console.log(userId);
        setUserIds((prevValue) => prevValue.filter((id) => id !== userId));
    }

    return (
        <>
            <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center vh-100 vw-100 ">
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#fff" size={50} />
                </Link>
                <Row className="d-flex justify-content-center align-items-center flex-row mb-1  mt-5 ">
                    <Col className="col-4 w-80 ">

                        <Image src="https://butiko-public-media-storage1.s3.eu-central-1.amazonaws.com/logo.png" fluid />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center   ">
                    <Col className="d-flex justify-content-center align-items-center ">

                        <Form onSubmit={handleSubmit}>
                            <h2>Push notification</h2>
                            <Form.Group>
                                <Form.Label>User Type:</Form.Label>
                                <Form.Select value={userType} onChange={(e) => setUserType(e.target.value)} className="mb-3" aria-label="Default select example">
                                    <option value="all_users">All Users</option>
                                    <option value="all_sellers">All Sellers</option>
                                    <option value="all_customers">All Customers</option>
                                    <option value="group">Group</option>
                                </Form.Select>
                            </Form.Group>


                            {userType === "group" &&
                                <>
                                    <Form.Group className="mb-3" controlId="formBasicInvoiceId">
                                        <Form.Label>Users: </Form.Label>
                                        <Form.Control onKeyDown={handleKeyDown} type="number" placeholder="Search..."  />
                                    </Form.Group>
                                    <div className="display-container">
                                        <div className="display-names">
                                            {userIds.map((value, index) => (
                                                <span onClick={() => {
                                                    handleUserDelet(value)
                                                }} key={index} className="display-name">
                                                    {value}<RxCross2 style={{marginLeft: "5px", cursor: "pointer"}} />
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </>

                            }
                            
                            <Form.Group className="mb-3" controlId="formBasicInvoiceId">
                                <Form.Label>Title: </Form.Label>
                                <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder="Title" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label> Content:</Form.Label>
                                <Form.Control required value={content} onChange={(e) => setContent(e.target.value)} as="textarea" rows={3} />
                                {/* <ReactQuill formats={formats} modules={modules} theme="snow" value={content} onChange={setContent} /> */}
                            </Form.Group>


                            <Button disabled={!content} className="border-0 butiko-button" variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default NotificationPage