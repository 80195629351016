import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { ToastContainer } from 'react-toastify';
import { Check } from 'lucide-react';
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd"
import useGetContracts from "./hooks/useGetContracts";
import formatDate from "../../components/formatDate";
import { Download } from 'lucide-react';
import NewContractModal from "./modals/NewContractModal";

const Contract = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(0);
    // console.log(page);

    const {
        contracts,
        isLoading,
        isError,
        error,
        refetch,
        isNext
    } = useGetContracts(page)
    // console.log(contracts);

    return (
        <>
            <Container className="d-flex justify-content-center ">
                <Row className="flex justify-start ">
                    <Col className="flex justify-start ">
                        <Link
                            style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                            to={"/nextpageoflogin"}
                        >
                            <IoChevronBack color="#fff" size={50} />
                        </Link>
                    </Col>
                </Row>

                <div className="container mt-20 mx-auto p-4">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold text-white">Contract List</h2>

                        <button onClick={() => setShow(true)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            New Contract
                        </button>
                        <NewContractModal show={show} setShow={setShow} />
                    </div>

                    <div className="w-full overflow-x-auto shadow-md rounded-lg">
                        <table className="w-full text-sm text-left">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3">Contract ID</th>
                                    <th className="px-6 py-3">Contract Name</th>
                                    <th className="px-6 py-3">Publish Date</th>
                                    <th className="px-6 py-3">Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr className="bg-gray-200 ">
                                        <th className="border p-2">
                                            <div className="bg-gray-300 h-6 w-16 rounded animate-pulse"></div>
                                        </th>
                                        <th className="border p-2">
                                            <div className="bg-gray-300 h-6 w-24 rounded animate-pulse"></div>
                                        </th>
                                        <th className="border p-2">
                                            <div className="bg-gray-300 h-6 w-28 rounded animate-pulse"></div>
                                        </th>
                                        <th className="border p-2">
                                            <div className="bg-gray-300 h-6 w-20 rounded animate-pulse"></div>
                                        </th>
                                    </tr>
                                ) : isError ? (
                                    <td colSpan="4" className="bg-red-300 border p-2 text-center text-red-500">
                                        An error occurred while loading data
                                    </td>
                                ) : (Array.isArray(contracts) && contracts
                                    .slice(0, isNext ? -1 : contracts.length) 
                                    .map((contract) => (
                                        <tr
                                            key={contract.contract_id}
                                            className="bg-white border-b hover:bg-gray-50"
                                        >
                                            <td className="px-6 py-4">
                                                {contract.contract_id}
                                            </td>
                                            <td className="px-6 py-4">
                                                {contract.contract_name}
                                            </td>
                                            <td className="px-6 py-4">
                                                {formatDate(contract.publish_date)}
                                            </td>
                                            <td className="px-6 py-4">
                                                <a
                                                    target="blank"
                                                    href={contract.contract_link}
                                                    download
                                                    className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                                                >
                                                    <Download size={16} />
                                                    <span>Download</span>
                                                </a>
                                            </td>
                                        </tr>
                                    )))
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 flex justify-between">
                        <button
                            onClick={() => { if (page !== 0) setPage(prev => prev - 1) }}
                            disabled={page === 0}
                            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Previous
                        </button>
                        <button
                            onClick={() => setPage(prev => prev + 1)}
                            disabled={!isNext}
                            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Next
                        </button>
                    </div>
                </div>


                {contextHolder}

                <ToastContainer />
            </Container>
        </>
    )
}
export default Contract