import React from "react"
import { useState, useMemo, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
const Token = () => {
    const history = useNavigate();
    const [sellerId, setSelleId] = useState("");
    const [sellerName, setSellerName] = useState("");
    const [companyTitle, setCompanyTitle] = useState("");
    const [shopName, setShopName] = useState("");
    const [amount, setAmount] = useState(null);

    // useEffect(() => {

    //     const isLoggedIn = localStorage.getItem('isLoggedIn');
    //     if (!isLoggedIn) {
    //         history("/")
    //     } else {
    //         // setLoggedInStatus(isLoggedIn);
    //     }
    // }, [history])
    const getSellerInfo = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/token/get-seller-info?seller_id=${sellerId}`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const {
        data: sellerInfo,
        isLoading: sellerInfoIsLoading,
        isError: sellerInfoIsError,
        error: sellerInfoError,
        refetch: sellerInfoRefetch
    } = useQuery({ queryKey: ["get-seller-info"], queryFn: getSellerInfo, enabled: false, retry: false })
    let sellerInfoData
    if (sellerInfoIsLoading) {
        sellerInfoData = "loading"
    } else if (sellerInfoIsError) {
        console.log(sellerInfoError);
        sellerInfoData = "error"

    } else {
        // console.log(sellerInfo?.data);
        sellerInfoData = sellerInfo?.data
        // setSellerName(sellerInfo.data.name)
    }
    useMemo(() => {
        setSellerName(sellerInfo?.data?.name_surname)
        setCompanyTitle(sellerInfo?.data?.company_title)
        setShopName(sellerInfo?.data?.shop_name)
        if (sellerInfoData === 'error') {
            setSellerName("Not Found")
            setShopName("Not Found")
            setCompanyTitle("Not Found")
        }
    }, [sellerInfoData])
    const giftToken = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/token/gift-token`
        const finalData = {
            seller_id: sellerId,
            amount: amount
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const Msg = ({ closeToast, toastProps }) => (
        <div className="bg bg-dark-subtle ">
            Lorem ipsum dolor {toastProps.position}
            <p>this is a paragraf</p>
            <h1>this is h1</h1>
            <Image src="https://butiko-public-media-storage1.s3.eu-central-1.amazonaws.com/logo.png" fluid />
            <button>Retry</button>
            <button onClick={closeToast}>Close</button>
        </div>
    );
    const tokenMutation = useMutation({
        mutationFn: giftToken,
        onSuccess: (data) => {
            // console.log(data);
            setSelleId("")
            setSellerName("")
            setCompanyTitle("")
            setShopName("")
            setAmount("")
            toast.success("Done")

        },
        onError: (error) => {
            console.log(error);
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        tokenMutation.mutate()
    }

    return (
        <>
            <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center vh-100 vw-100 ">
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#fff" size={50} />
                </Link>
                <Row className="d-flex justify-content-center align-items-center flex-row mb-5 mt-5 ">
                    <Col className="col-4 w-30 ">

                        <Image src="https://butiko-public-media-storage1.s3.eu-central-1.amazonaws.com/logo.png" fluid />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center   ">
                    <Col className="d-flex justify-content-center align-items-center ">

                        <Form onSubmit={handleSubmit}>
                            <h2>Token Submission</h2>
                            <Form.Group className="mb-3" controlId="formBasicSellerId">
                                <Form.Label>Seller ID:</Form.Label>
                                <Form.Control value={sellerId} onChange={(e) => setSelleId(e.target.value)} type="number" placeholder="Seller ID" required />
                            </Form.Group>
                            <Button onClick={sellerInfoRefetch} className="border-0 butiko-button" variant="primary" type="button">
                                Get Seller Info
                            </Button>

                            <Form.Group className="mb-3" controlId="formBasicInvoiceId">
                                <Form.Label>Name - Surname:</Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={sellerName} type="text" placeholder="Name" required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Company Title: </Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={companyTitle} type="text" placeholder="Company titile" required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Shop name:</Form.Label>
                                <Form.Control className={`${sellerInfoData === "error" ? ('text-danger border-danger ') : ('')}`} disabled value={shopName} type="text" placeholder="Shop name" required />
                            </Form.Group>

                            <Form.Group className="mb-5" controlId="formBasicPassword">
                                <Form.Label>Amount: </Form.Label>
                                <Form.Control value={amount} onChange={(e) => setAmount(e.target.value)} type="number" placeholder="Amout" required />

                            </Form.Group>

                            <Button disabled={sellerInfoData === 'error' || !sellerInfoData} className="border-0 butiko-button" variant="primary" type="submit">
                                Submit
                            </Button>
                            {/* <Button onClick={() => {
                                toast(<Msg />);
                            }} disabled={sellerInfoData === 'error' || !sellerInfoData} className="border-0 butiko-button mt-5" variant="primary" type="button">
                                toast
                            </Button> */}
                        </Form>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default Token;