import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import useGetOrders from "./hooks/useGetOrders";
import { useState } from "react";
import OrderDetailModal from "./modals/OrderDetailModal";
export default function Orders() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [orderDetails, setOrderDetails] = useState([]);
    const { orders, isLoading, isError, error, refetch, isNext } = useGetOrders(page);
    const handleNextPage = () => {
        setPage(prev => prev + 1);
    };
    const handlePrevPage = () => {
        if (page > 0) {
            setPage(prev => prev - 1);
        }
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    return (
        <div className="">
            <div>
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#fff" size={50} />
                </Link>
            </div>

            <div className="container mt-20 w-full">
                <h1 className="text-2xl font-bold mb-4 text-white">Orders</h1>

                {isLoading ? (
                    <p className="text-white">Loading...</p>
                ) : isError ? (
                    <p className="text-red-500">Error</p>
                ) : (
                <>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md rounded-lg">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="px-6 py-3 border-b text-left">Order ID</th>
                                    <th className="px-6 py-3 border-b text-left">Customer Name</th>
                                    <th className="px-6 py-3 border-b text-left">Customer Address</th>
                                    <th className="px-6 py-3 border-b text-left">Invoice Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order) => (
                                    <tr onClick={()=>{
                                        setOrderDetails(order.order_details);
                                        showModal();
                                    }} key={order.order_id} className="hover:bg-gray-50 cursor-pointer">
                                        <td className="px-6 py-4 border-b">{order.order_id}</td>
                                        <td className="px-6 py-4 border-b">{order.customer_name}</td>
                                        <td className="px-6 py-4 border-b">{order.customer_address}</td>
                                        <td className="px-6 py-4 border-b">
                                            {order.is_invoice_uploaded ? (
                                                <span className="text-green-500">Uploaded</span>
                                            ) : (
                                                <span className="text-red-500">Not Uploaded</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                        <div className="flex w-full justify-end gap-4 mt-4">
                            <button
                                onClick={handlePrevPage}
                                disabled={page === 0}
                                className={`px-4 py-2 rounded ${page === 0
                                        ? 'bg-gray-300 cursor-not-allowed'
                                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                                    }`}
                            >
                                Previous
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={!isNext}
                                className={`px-4 py-2 rounded ${!isNext
                                        ? 'bg-gray-300 cursor-not-allowed'
                                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                                    }`}
                            >
                                Next
                            </button>
                        </div>
                </>
                )}
            </div>
            <OrderDetailModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} orderDetails={orderDetails} />
        </div>
    );
}